export const SHADOW_VIEW_INHERITED_PROPERTIES = [
  'border-collapse',
  'border-spacing',
  'caption-side',
  'color',
  'cursor',
  'direction',
  'empty-cells',
  'letter-spacing',
  'line-height',
  'list-style-image',
  'list-style-position',
  'list-style-type',
  'list-style',
  'orphans',
  'quotes',
  'tab-size',
  'text-align',
  'text-align-last',
  'text-decoration-color',
  'text-indent',
  'text-justify',
  'text-shadow',
  'text-transform',
  'visibility',
  'white-space',
  'widows',
  'word-break',
  'word-spacing',
  'word-wrap',
];
