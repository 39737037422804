import React, { useState } from 'react';
import { createPortal } from 'react-dom';

import styles from './styles.css';
import { attachStyles, isShadowDomAvailable } from './utils';

interface Props {
  shouldInheritFontFamily: boolean;
  shouldInheritColor: boolean;
  children: React.ReactElement;
}

export function ShadowView({
  shouldInheritFontFamily,
  shouldInheritColor,
  children,
}: Props) {
  const [shadowRoot, setShadowRoot] = useState<ShadowRoot | null>(null);
  const recordOfInheritedProperties = {
    'font-family': shouldInheritFontFamily,
    color: shouldInheritColor,
  };

  const setRoot = (element: HTMLElement | null) => {
    if (element && !element.shadowRoot) {
      const shadow = element.attachShadow({ mode: 'open' });
      attachStyles(shadow, recordOfInheritedProperties, shouldInheritColor);
      setShadowRoot(shadow);
    }
  };

  return isShadowDomAvailable ? (
    <>
      {!shadowRoot && children}
      <span ref={setRoot} className={styles.containerPortal}>
        {shadowRoot && createPortal(children, shadowRoot as any)}
      </span>
    </>
  ) : (
    children
  );
}
