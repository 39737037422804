// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".styles__subtitle--abcd5 {\n  margin: 0 0 24px;\n  font-size: 16px;\n  line-height: 24px;\n  letter-spacing: -0.011em;\n  color: #acacb6;\n}\n", "",{"version":3,"sources":["webpack://./src/components/card-widget/components/subtitle/styles.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;EAChB,eAAe;EACf,iBAAiB;EACjB,wBAAwB;EACxB,cAAc;AAChB","sourcesContent":[".subtitle {\n  margin: 0 0 24px;\n  font-size: 16px;\n  line-height: 24px;\n  letter-spacing: -0.011em;\n  color: #acacb6;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"subtitle": "styles__subtitle--abcd5"
};
export default ___CSS_LOADER_EXPORT___;
