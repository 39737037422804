import { SHADOW_VIEW_INHERITED_PROPERTIES } from './constants';

export const getActualInheritedProperties = (
  inheritedProps: Record<string, boolean>
) => SHADOW_VIEW_INHERITED_PROPERTIES.filter((prop) => !inheritedProps[prop]);

export const getTabbyStyles = () =>
  Array.from(document.querySelectorAll('style[data-app="tabby"]'));

export const cloneStylesToShadowDom =
  (shadowRoot: ShadowRoot | null) => (node: Element) => {
    const nodeClone = node.cloneNode(true);
    shadowRoot?.appendChild(nodeClone);
  };

export const isShadowDomAvailable = Boolean(
  document.head.attachShadow || (document.head as any).createShadowRoot
);

export const attachStyles = (
  element: ShadowRoot,
  recordOfInheritedProperties: Record<string, boolean>,
  shouldInheritColor: boolean
) => {
  element.innerHTML += `
    <style>
      :host {
        ${getActualInheritedProperties(recordOfInheritedProperties)
          .map((property) => `${property}: initial;`)
          .join('')}
        ${
          shouldInheritColor &&
          `--snippetTextColor: initial;
     --snippetLinkTextColor: initial;`
        } 
      }
    </style>`;

  const tabbyStyles = getTabbyStyles();
  tabbyStyles.forEach(cloneStylesToShadowDom(element));
};
