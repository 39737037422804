import cn from 'classnames';
import React, { ReactNode } from 'react';
import { useAppContext } from 'src/context/store/app-provider';
import { CardTheme } from 'src/types/card-theme';

import styles from './styles.css';

interface Item {
  title: string;
  description: ReactNode;
}

interface Props {
  list: [Item, Item, Item, Item];
}

export function Line({ list }: Props) {
  const { isRtl, size, theme = 'default' } = useAppContext<CardTheme>();

  return (
    <div
      className={cn(styles.line, size === 'wide' ? styles.lineSizeWide : null)}
    >
      {list.map((item, index) => (
        <div
          key={index}
          className={cn(
            styles.item,
            isRtl ? styles.itemRtl : null,
            size === 'wide' ? styles.itemSizeWide : null
          )}
        >
          <div
            className={cn(
              styles.ellipse,
              size === 'wide' && styles.ellipseWide
            )}
          >
            <div
              className={cn(
                styles[`ellipseSection${index + 1}`],
                theme === 'black'
                  ? styles[`ellipseSection${index + 1}ThemeBlack`]
                  : null
              )}
            />
          </div>

          <div
            className={cn(
              styles.text,
              isRtl ? styles.textRtl : null,
              size === 'wide' ? styles.textSizeWide : null
            )}
          >
            <p>{item.title}</p>
            {item.description}
          </div>
        </div>
      ))}
    </div>
  );
}
