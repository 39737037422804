/* https://tyapk.ru/blog/post/correct-way-to-import-lodash-methods */
import get from 'lodash.get';
import arDictionaryContent from 'src/locales/ar.json';
import arQaDictionaryContent from 'src/locales/ar-QA.json';
import enDictionaryContent from 'src/locales/en.json';
import { Lang, LangDictionary, Locale } from 'src/types/lang-locale-type';
import { getLangCode } from 'src/utils/get-lang-code';
import { getLocale } from 'src/utils/get-locale';

const dictionaryContent: {
  [key in LangDictionary]: any;
} = {
  [LangDictionary.en]: enDictionaryContent,
  [LangDictionary.ar]: arDictionaryContent,
  [LangDictionary['ar-QA']]: arQaDictionaryContent,
};

const LocaleDictionaryMap: {
  [key in Locale]: LangDictionary;
} = {
  [Locale['en-US']]: LangDictionary.en,
  [Locale['ar-AE']]: LangDictionary.ar,
  [Locale['ar-BH']]: LangDictionary.ar,
  [Locale['ar-KW']]: LangDictionary.ar,
  [Locale['ar-SA']]: LangDictionary.ar,
  [Locale['ar-QA']]: LangDictionary['ar-QA'],
};

const replace = (source = '', target = '', val = '') =>
  source.replace(new RegExp(`{{${target}}}`, 'g'), val);

const getDictionaryName = (
  lang: string | Lang,
  locale: string | Locale
): LangDictionary => {
  const langCode = getLangCode(lang as Lang);

  const localeCode = locale || getLocale(langCode, locale as Locale);

  if (localeCode in LocaleDictionaryMap) {
    return LocaleDictionaryMap[localeCode];
  }

  throw new Error(`Unexpected locale code: ${localeCode}`);
};

const getTranslation = (lang: Lang, locale: Locale, value: string): string => {
  const dictionaryName = getDictionaryName(lang, locale);
  const currentDictionaryContent: LangDictionary =
    dictionaryContent[dictionaryName];

  return get(currentDictionaryContent, value, value);
};

const getTranslationString = (
  lang: Lang,
  locale: Locale,
  value: string,
  params: Record<string, any> | null = null
) => {
  const translation = getTranslation(lang, locale, value);

  if (!params) return translation;

  return Object.keys(params).reduce(
    (prev, key) => replace(prev, key, params[key]),
    translation
  );
};

export const getTranslationFn =
  (lang: Lang, locale: Locale) =>
  (value: string, params?: Record<string, unknown>) =>
    getTranslationString(lang, locale, value, params);
