// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".styles__containerPortal--c9f84 {\n  display: -webkit-flex;\n  display: -ms-flexbox;\n  display: flex;\n}\n", "",{"version":3,"sources":["webpack://./src/components/shadow-view/styles.css"],"names":[],"mappings":"AAAA;EACE,qBAAa;EAAb,oBAAa;EAAb,aAAa;AACf","sourcesContent":[".containerPortal {\n  display: flex;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"containerPortal": "styles__containerPortal--c9f84"
};
export default ___CSS_LOADER_EXPORT___;
